*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// CSS Vars for Javascript:

:root {
  --animation-time: 2s;
}

html {
  max-width: 100vw;

  font-size: 62.5%;

  @include respond(tabLand) { // width < 1200
    font-size: 56.25%; // 9px
  }

  @include respond(tabPort) { // width < 900
    font-size: 50%; // 8px
  }

  @include respond(bigDesktop) {
    font-size: 75%; // 12px
  }


}

body {
  box-sizing: border-box;

  @include respond(tabPort) { // width < 900
    padding: 0;
  }
}


/* ---- reset ---- */
canvas {
  display: block;
  vertical-align: bottom;
} /* ---- particles.js container ---- */
#particles-js {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
} /* ---- stats.js ---- */
.count-particles {
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13e8e9;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.js-count-particles {
  font-size: 1.1em;
}
#stats,
.count-particles {
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}
#stats {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}
.count-particles {
  border-radius: 0 0 3px 3px;
}
