.clients {
  padding-top: 25rem;
  width: 100%;
  height: 46.8rem;
  background-color: rgba(218, 218, 218, 0.19);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -20rem;

  @include respond(tabLand) {
    height: auto;
  }


  &__title {
    width: 100%;
    height: 3.6rem;
    color: rgb(0, 0, 0);
    font-family: $font-primary;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 2.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo-box {
    width: 100%;
    display: flex;
    padding: 0 20% 0 20%;
    justify-content: space-between;
    align-content: center;

    @include respond(tabLand) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__logo {
    opacity: 0.25;
    margin: 5rem;

  }
}
