.header {
  &__container {
    width: 100vw;
    height: 80rem;
    background-image: linear-gradient(180deg, #63b239 0%, #83c64c 31%, #9bd65a 100%, #ffffff 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 75vh, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
  }

  &__content-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
  }

  &__left-side{
    padding: 0 0 0 8%;
    max-width: 50%;
  }

  &__main-title {

    color: $color-white;
    font-family: $font-primary;
    font-size: 5.5rem;
    font-weight: 100;
    line-height: 7.2rem;
    font-style: normal;
    margin-bottom: 1rem;
  }

  &__subtitle {

    color: $color-white;
    font-family: $font-primary;
    font-size: 2.6rem;
    font-weight: 100;
    line-height: 5.9rem;
    margin-bottom: 5.8rem
  }



  &__right-side {
    width: 50%;
    height: 100%;
    position: relative;
    transform: translateY(-3rem);
  }

  &__blob {
    width: 90%;
    // height: 50%;
    position: absolute;
    z-index: 1;
    top: 25%;
  }

  &__city {
    width: 50%;
    // height: 50%;
    position: absolute;
    transform: translate(25vw, -20%);
    z-index: 2;
    top: 25%;
  }

  &__laptop {
    width: 50%;
    // height: 50%;
    position: absolute;
    z-index: 3;
    right: 50%;
    top: 25%;
  }


}
