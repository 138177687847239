.footer {

  &__form-container {
    height: 60.7rem;
    border: 0.1rem solid #979797;
    background-image: linear-gradient(180deg, #9e3a8d 0%, #78337b 100%);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }


  &__title {
    text-align: center;
    width: 100%;
    color: #ffffff;
    font-family: $font-primary;
    font-size: 4rem;
    font-weight: 700;
    line-height: 5.8rem;

    letter-spacing: 0.02rem;
  }

  &__form {
    width: 91rem;
    display: flex;
    flex-wrap: wrap;

    &-field{
      width: 43rem;
      height: 6.8rem;
      border-radius: 3.4rem;
      background-color: #ffffff;
      padding-left: 3.3rem;
      color: rgba(0, 0, 0, 0.43);
      font-family: $font-primary;
      font-size: 2.2rem;
      font-weight: 100;
      margin-bottom: 3rem;
      border: none;

      &:not(:nth-child(2n)) {
        margin-right: 3.8rem;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__end {
    height: 7.6rem;
    background-color: rgb(113, 187, 65);

    display: flex;
    justify-content: center;
    align-items: center;

    &-link {
      color: rgb(255, 255, 255);
      font-family: $font-primary;
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: 0.08rem;
      padding-left: 1rem;


      &:not(:last-child) {
        border-right: 1px solid white;
        padding-right: 1rem;
      }
    }

  }
}
