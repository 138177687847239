.info {


  &__how-it-works {
    // height: 40.5rem;
    padding: 10rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__left-side {
    display: flex;
    flex-direction: column;
    padding-right: 10rem;

  }

  &__right-side {
    padding: 5rem;
    & img {
      width: 100%;
      box-shadow: 0 0.2rem 0.8rem 0.2rem rgba(178, 0, 178, 0.1);
    }
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__body {
    width: 76.3rem;
    height: 8.4rem;
    color: rgb(0, 0, 0);
    font-family: $font-primary;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 2.9rem;
    line-height: 3.3rem;
    // text-align: center;
  }
}


.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 58.5rem;
  background-color: rgba(218, 218, 218, 0.19);
  @include respond(tabLand) {
    height: auto;
  }

  &__header {
    margin-top: 7.8rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__wrapper {
    display: flex;

    @include respond(tabLand) {
      flex-direction: column;
      margin: 5rem;
    }
  }

  &__statistic-section {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    @include respond(tabLand) {
      padding: 5rem 0;
    }

    &:not(:last-child) {
      margin-right: 13rem;

      @include respond(tabLand) {
        margin: auto;
      }
    }

  }

  &__percentage {
    position: absolute;
    right: 7rem;
    top: -3rem;

    color: #000000;
    font-family: $font-primary;
    font-size: 22px;
    font-weight: 700;

    @include respond(tabLand) {
      top: 0;
      right: 5rem;
    }
  }

  &__chart {
    width: 21rem;
  }

  &__description {
    width: 28rem;
    color: #000000;
    font-family: $font-primary;
    font-size: 22px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    margin-top: 6rem;
  }
}




.features-extras {
  height: 62rem;
  display: flex;
  position: relative;
  justify-content: center;

  @include respond(tabLand) {
    height: auto;
    flex-direction: column;
    padding-bottom: 15rem;
  }

  &__feature-section {
    display: flex;
    flex-direction: column;

    @include respond(tabLand) {
      align-items: center;
    }

  }

  &__header {
    margin-bottom: 3.7rem;
    margin-top: 8rem;
  }

  &__feature {
    display: flex;
    align-items: center;
    color: #000000;
    font-family: $font-primary;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 4.4rem;

    &-text {
      margin-left: 3rem
    }
  }

  &__extra {
    display: flex;
    align-items: center;
    color: #000000;
    font-family: $font-primary;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 4.4rem;

    &-text {
      margin-left: 3rem
    }
  }

  &__leaf-left {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1
  }
  &__leaf-right {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1
  }
}


.waste {
  height: 90rem;
  background-color: rgba(171, 171, 171, 0.19);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include respond(tabLand) {
    height: auto;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
    width: 100%;
  }

  &__cards {
    display: flex;
    align-items: center;

    @include respond(tabLand) {
      flex-direction: column;
    }
  }

  &__card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 39.2rem;
    height: 50.6rem;
    box-shadow: 0 0.2rem 0.8rem 0.2rem rgba(178, 0, 178, 0.1);
    border-radius: 1rem;
    background-color: #ffffff;
    transition: all 0.2s;

    @include respond(tabLand) {
      margin: 2rem 0;
    }

    &:not(:last-child) {
      margin-right: 6.5rem;

      @include respond(tabLand) {
        margin-right: 0;
      }
    }



    &--big{
      width: 44.8rem;
      height: 57.8rem;
      box-shadow: 0 0.2rem 0.8rem 0.2rem rgba(178, 0, 178, 0.1);
      border-radius: 1rem;
      background-color: #ffffff;
    }

    &-description {
      color: #000000;
      font-family: $font-primary;
      font-size: 2.2rem;
      font-weight: 400;
      line-height: 3.2rem;
      width: 80%;
      text-align: center;
      margin-top: 3.6rem;
    }

    &-quote{
      color: #71bb41;
      font-family: $font-primary;
      font-style: italic;
      font-weight: 600;
      text-align: center;
      font-size: 2.2rem;
      margin-top: 4rem;
    }

    &:hover{
      transform: scale(1.025);
    }

    &:hover &-icon{
      animation: Icon-float infinite 3s ease-out;
    }
  }

}

#GreenBagWrapper {
  animation: Float infinite 5s ease-out;
}

#YellowBag {
  animation: Sink infinite 5s ease-out;
}

.house-percentage {
  animation: Pulse infinite 2s ease-out;
}

.tonnes {
  animation: Pulse infinite 4s ease-out;
}

@keyframes Pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Float {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

@keyframes Sink {
  0% {
    transform: translateY(1rem);
  }
  35% {
    transform: translateY(0rem);
  }
  100% {
    transform: translateY(1rem);
  }
}


// branch animation

.features-extras__branch-wrapper {
  animation: Breeze var(--animation-time) infinite;
  transform-origin: bottom;
}


@keyframes Breeze {
  0% {
    transform: rotate3d(0,0,1, 2deg);
  }
  35% {
    transform: rotate3d(0,0,1, 0deg);
  }
  100% {
    transform: rotate3d(0,0,1, 2deg);
  }
}
