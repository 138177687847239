.header {
  &--purple {
    height: 5rem;
    color: rgb(178, 0, 178);
    font-family: $font-primary;
    font-size: 4rem;
    font-weight: 700;
    line-height: 5.8rem;
    letter-spacing: 0.02rem;
  }

  &--green {
    height: 5rem;
    color: rgb(113, 187, 65);
    font-family: $font-primary;
    font-size: 4rem;
    font-weight: 700;
    line-height: 5.8rem;
    letter-spacing: 0.02rem;
  }
}
