.demo-btn {
  &--purple {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24.4rem;
    height: 7rem;
    border-radius: 3.5rem;
    border: none;
    background-color: rgb(178, 0, 178);

    color: rgb(255, 255, 255);
    font-family: $font-primary;
    font-size: 2.2rem;
    font-weight: 700;
    position: absolute;
    // display: none;
    z-index: 1000000000;
  }

  &--green {
    width: 24.4rem;
    height: 7rem;
    border-radius: 3.5rem;
    border: none;
    background-color: #71bb41;

    color: rgb(255, 255, 255);
    font-family: $font-primary;
    font-size: 2.2rem;
    font-weight: 400;
  }
}
